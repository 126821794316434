<script>
export const pageName = 'membership';
export const pageRoute = '/membership';
export const title = 'Join'
export const requireMembership = false;
export const requireCompleteAccount = true;

import { getCurrentUserId } from '../../js/auth.js';
import { functions } from '../../js/lib/functions.js';
import { listenToProjection } from '../../js/lib/client-read-model.js';
import { navigate, PageShowError } from '@trullock/page-manager'
import { hideLoading, showLoading } from '../../js/utils/loading.js';
import { showToast } from '../../js/utils/toast.js'
import { loadStripe } from '../../js/utils/stripe.js'
import DateTime from '../../../functions/lib/dateTime.js'
import UserView from '../../../functions/domain/users/userView.js';
import { getMembershipImportDate } from '../../../functions/src/site.config.js';

export default {
	data() {
		return {
			currentUser: null,
			mode: null,
			importDate: getMembershipImportDate(),
			ready: false
		}
	},

	methods: {
		async boot(opts) {
			await listenToProjection([UserView, getCurrentUserId()], user => this.currentUser = user);
			this.stripe = await loadStripe();
			this.ready = true;
		},

		async show(opts) {
			
			if(this.currentUser.membership.status == 'active' && (DateTime.today.month < 12 || this.currentUser.membership.expiresOn.year > DateTime.today.year))
				throw new PageShowError('/', 'You already have an active membership', {}, 'replace');

			if(this.currentUser.wasImported && DateTime.now < new DateTime(2026, 1, 1))
			{
				this.mode = 'free';
			}
			else
			{
				this.mode = 'paid';

				let response = await functions.stripeCreateCheckoutSession();
				this.checkout = await this.stripe.initEmbeddedCheckout({
					fetchClientSecret: () => response.client_secret
				});

				this.checkout.mount(this.$refs.stripeMount);
			}
		},

		async renew() {
			showLoading();

			let result = await functions.userRenewMembershipForFree();
			if(!result.success)
			{
				showToast({ message: 'Error renewing membership', style: 'bg-danger'})
				hideLoading();
				return;
			}

			showToast({
				message: `Membership renewed until ${result.expiresOn.format('yyyy/MM/dd')}`,
				style: 'bg-success'
			})

			navigate('/')

			hideLoading();
		},

		hide()
		{
			this.checkout?.destroy();
		}
	},
	props: [ 'options' ]
}

</script>
<template>
	<div v-if="ready" class="container py-5">
		<div class="row">
			<div class="col col-12">
				<div class="card">
					<div class="card-header">
						<h4 class="card-header-title">Membership</h4>
					</div>
					<div class="card-body">
						<div ref="stripeMount" v-if="mode == 'paid'"></div>
						<div v-else>
							<p>UKRA membership renewals are free until 2026 for members who joined before {{ importDate.format('yyyy/MM/dd')}}, however you must still renew your membership.</p>
							<div class="display-3 mb-5">£0.00</div>
							<button @click.prevent="renew" class="btn btn-primary">Renew membership <span class="fe fe-arrow-right-circle"></span></button>
						</div>
					</div>
				</div>
			</div>
		</div>
    </div>
</template>
