<script>
import { getUserView } from '../../js/readModel.js';
import { functions } from '../../js/lib/functions.js'
import { navigate } from '@trullock/page-manager'
import Modal from '../../js/utils/modal.js';
import Cropper from 'cropperjs'
import { hideLoading, showLoading } from '../../js/utils/loading.js';
import DateTime from '../../../functions/lib/dateTime.js'
import { showToast } from '../../js/utils/toast.js'

export const pageName = 'admin-user-edit';
export const pageRoute = '/admin/user/{userId}/edit';
export const title = 'Edit User'
export const requireAdmin = true;

export default {
	data() {
		return { 
			user: null,
			name: '',
			email: '',
			number: 0,
			bornOn: null,
			memberSince: null,
			maxDob: DateTime.today,
			minDob: DateTime.today.addYears(-120),
			BMFANumber: '',
			BMFASurname: '',
			avatar: null,
			certL1: false,
			certL2: false,
			certL3: false,
			certRSO: false,
			files: [],
			ready: false
		}
	},
	methods: {
		async submit() {			
			showLoading();

			this.$refs.number.setCustomValidity('');
			this.$refs.bmfaNumber.setCustomValidity('');
			this.$refs.bmfaSurname.setCustomValidity('');
			this.$refs.bornOn.setCustomValidity('');

			if(this.name != this.user.name)
			{
				let result = await functions.userChangeName({ id: this.user.id, name: this.name});
				if(!result.success)
				{
					showToast({ message: 'Error changing user\'s name: ' + result.error, style: 'bg-danger'})
					hideLoading();
					return;
				}
			}

			if(this.email != this.user.email)
			{
				let result = await functions.userChangeEmail({ id: this.user.id, email: this.email});
				if(!result.success)
				{
					if(result.error == 'auth/email-already-exists')
					{
						this.$refs.bornOn.setCustomValidity('A user with this email address already exists');
						this.$refs.bornOn.reportValidity();
					}
					else
					{
						showToast({ message: 'Error changing user\'s email: ' + result.error, style: 'bg-danger'})
					}
					
					hideLoading();
					return;
				}
			}
			
			let bornOn = this.bornOn ? new DateTime(this.bornOn) : null
			if(bornOn && !bornOn.equals(this.user.bornOn))
			{
				let result = await functions.userChangeBornOn({ id: this.user.id, bornOn});
				if(!result.success)
				{
					showToast({ message: 'Error changing user\'s DoB: ' + result.error, style: 'bg-danger'})
					hideLoading();
					return;
				}
			}

			if(this.number != this.user.number)
			{
				let result = await functions.queryNumber({
					userId: this.user.id,
					number: this.number
				})

				if(!result.status)
				{
					this.$refs.number.setCustomValidity('Already in use');
					this.$refs.number.reportValidity();

					hideLoading();
					return;
				}

				result = await functions.userChangeNumber({ userId: this.user.id, number: this.number});
				if(!result.success)
				{
					showToast({ message: 'Error changing user\'s number: ' + result.error, style: 'bg-danger'})
					hideLoading();
					return;
				}
			}

			let createdOn = new DateTime(this.memberSince)
			if(createdOn != this.user.createdOn)
			{
				let result = await functions.userChangeCreatedOn({ userId: this.user.id, createdOn});
				if(!result.success)
				{
					showToast({ message: 'Error changing user\'s Member-Since: ' + result.error, style: 'bg-danger'})
					hideLoading();
					return;
				}
			}

			if(this.BMFANumber != this.user.BMFANumber || this.BMFASurname != this.user.BMFASurname)
			{
				let result = await functions.queryBMFA({
					surname: this.BMFASurname,
					number: this.BMFANumber
				})

				// Dont apply BMFA check to admin accounts. TODO: Bit crude, do something better to allow admin accounts to be Completed with no BMFA details
				if(result.success && !this.user.isAdmin)
				{
					if(result.status == 'expired')
					{
						this.$refs.bmfaSurname.setCustomValidity(' ');
						this.$refs.bmfaSurname.reportValidity();

						this.$refs.bmfaNumber.setCustomValidity('Insurance is not active');
						this.$refs.bmfaNumber.reportValidity();

						hideLoading();
						return;
					}
					else if(result.status == 'not-found')
					{
						this.$refs.bmfaSurname.setCustomValidity(' ');
						this.$refs.bmfaSurname.reportValidity();

						this.$refs.bmfaNumber.setCustomValidity('Insurance details not found');
						this.$refs.bmfaNumber.reportValidity();

						hideLoading();
						return;
					}
				}
				
				result = await functions.userChangeBMFA({ id: this.user.id, number: this.BMFANumber, surname: this.BMFASurname });
				if(!result.success)
				{
					showToast({ message: 'Error changing user\'s BMFA details: ' + result.error, style: 'bg-danger'})
					hideLoading();
					return;
				}
			}
			
			if(this.newAvatar)
			{
				let result = await functions.userChangeAvatar({ id: this.user.id, avatar64: this.newAvatar});
				if(!result.success)
				{
					showToast({ message: 'Error changing user\'s avatar: ' + result.error, style: 'bg-danger'})
					hideLoading();
					return;
				}
			}

			navigate('/admin/user/' + this.user.id)

			hideLoading();
		},
		boot(opts) {
			this.modal = new Modal(this.$refs.cropper);
		},
		async show(opts)
		{
			this.user = opts.user || await getUserView(opts.userId);
			this.name = this.user.name;
			this.number = this.user.number;
			this.bornOn = this.user.bornOn?.format('yyyy-MM-dd');
			this.memberSince = this.user.createdOn.format('yyyy-MM-dd')
			this.email = this.user.email;
			this.avatar = this.user.avatar;
			this.BMFANumber = this.user.BMFANumber
			this.BMFASurname = this.user.BMFASurname
			this.files = []
			this.ready = true;
		},
		hide()
		{
			this.$refs.fupAvatar.value = null
			this.$refs.lblAvatar.innerHTML = "Choose image&hellip;";
		},
		crop() {
			showLoading();
			
			this.modal.hide();

			// use a separate "thread" because of UI locking
			setTimeout(() => {
				
				let canvas = this.cropper.getCroppedCanvas({
					width: 240,
					height: 240
				});

				this.avatar = canvas.toDataURL();
				this.newAvatar = this.avatar;

				canvas.toBlob(blob => {
					this.$refs.fupAvatar.blobData = {
						blob: blob,
						name: 'avatar.jpg'
					};
					this.$refs.lblAvatar.innerText = this.$refs.lblAvatar.pendingName;
				});
			
				hideLoading();
			}, 1);
		},
		hideModal() {
			this.modal.hide()
			this.cropper.destroy();
			this.cropper = null;
		},
		inputChanged(e) {

			var files = e.target.files;
			var done = (url, name) => {
				this.$refs.fupAvatar.value = '';
				this.$refs.fupAvatar.blobData = null;
				this.$refs.imgCropper.src = url;

				let $img = new Image();
				$img.addEventListener('load', e => {
					this.$refs.lblAvatar.innerHTML = "Choose image&hellip;";
					this.$refs.lblAvatar.pendingName = name;
					if(this.cropper)
						this.cropper.destroy();

					let maxHeight = window.innerHeight - 230;
					this.$refs.imgCropper.style.maxHeight = maxHeight + 'px'

					this.modal.show();
					this.cropper = new Cropper(this.$refs.imgCropper, {
						aspectRatio: 1,
						viewMode: 1,
						autoCropArea: 1
					});
				})

				$img.addEventListener('error', e => {
					showToast({
						message: 'File not a recognised image',
						style: 'bg-danger'
					})
				})

				$img.src = url;
				
			};

			if (files && files.length > 0) {
				let file = files[0];
				if (URL) {
					done(URL.createObjectURL(file), file.name);
				} else if (FileReader) {
					let reader = new FileReader();
					reader.onload = function (e) {
						done(reader.result, file.name);
					};
					reader.readAsDataURL(file);
				}
			}
		}
	},
	props: [ 'options' ]
}
</script>
<template>
<div class="container" v-if="ready">
    <form @submit.prevent="submit">
		<div class="row justify-content-center py-5">
			<div class="col-12 col-md-8">
				
				<div class="card">
					<div class="card-header">
						<h4 class="card-header-title">Profile</h4>
					</div>
					<div class="card-body">
						<fieldset>
							<div class="form-group">
								<label>Name</label>
								<input type="text" class="form-control" v-model="name" autocomplete="off" placeholder="First Last" required />
								<span class="invalid-feedback"></span>
							</div>
							<legend>Contact Details</legend>
							<div class="form-group">
								<label>Email address</label>
								<input type="email" class="form-control" autocomplete="off" ref="bornOn" v-model="email" placeholder="e.g. user@example.com" required/>
							</div>
							<div class="form-group">
								<label>Date of birth</label>
								<input type="date" class="form-control" ref="dob" v-model="bornOn" :max="`${maxDob.format('yyyy-MM-dd')}`" :min="`${minDob.format('yyyy-MM-dd')}`"/>
								<span class="invalid-feedback"></span>
							</div>
						</fieldset>
						<hr>
						<fieldset>
							<div class="field form-group mb-0">
								<label>
									Picture
									<div class="dz-default dz-message py-5">
										<div class="avatar avatar-xxl">
											<img ref="imgAvatar" class="avatar-img rounded-circle" :src="avatar" alt="Avatar">
										</div>
									</div>
								</label>

								<div class="custom-file">
									<input type="file" @change="inputChanged" ref="fupAvatar" class="custom-file-input" accept="image/*">
									<label ref="lblAvatar" class="custom-file-label text-muted">Choose image&hellip;</label>
								</div>
								<span class="form-text text-muted small mb-0">Must be a jpeg or png</span>
							</div>
						</fieldset>
					</div>
				</div>
				<div class="card">
					<div class="card-header">
						<h4 class="card-header-title">Internal</h4>
					</div>
					<div class="card-body">
						<fieldset>
							<div class="form-group">
								<label>UKRA Number</label>
								<input type="number" step="1" min="1" max="99999" class="form-control" autocomplete="off" ref="number" v-model="number" placeholder="e.g. 123456" />
								<span class="invalid-feedback"></span>
							</div>
							<div class="form-group">
								<label>Member Since</label>
								<input type="date" class="form-control" v-model="memberSince" :max="`${maxDob.format('yyyy-MM-dd')}`"/>
								<span class="invalid-feedback"></span>
							</div>
						</fieldset>
					</div>
				</div>
				<div class="card">
					<div class="card-header">
						<h4 class="card-header-title">Insurance</h4>
					</div>
					<div class="card-body">
						<fieldset>
							<legend>Insurance details</legend>
							<div class="row">
								<div class="col">
									<div class="form-group mb-0">
										<label>BMFA Number</label>
										<input type="text" class="form-control" autocomplete="off" ref="bmfaNumber" v-model="BMFANumber" placeholder="e.g. 123456" />
										<span class="invalid-feedback"></span>
									</div>
								</div>
								<div class="col">
									<div class="form-group mb-0">
										<label>Surname</label>
										<input type="text" class="form-control" autocomplete="off" ref="bmfaSurname" v-model="BMFASurname" placeholder="e.g. Smith" />
										<span class="invalid-feedback"></span>
									</div>
								</div>
							</div>
						</fieldset>
					</div>
				</div>
				<button type="submit" class="btn btn-lg btn-primary btn-block">Save changes <span class="fe fe-check-circle"></span></button>
			</div>
		</div>
	</form>
</div>
<div class="modal fade" ref="cropper" tabindex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="card mb-0">
                <div class="card-header">
                    <h4 class="card-header-title">Crop the image</h4>
                    <button type="button" class="close" @click.prevent="hideModal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="card-body">
                    <div class="img-container">
                        <img ref="imgCropper" :src="avatar" class="">
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click.prevent="hideModal">Cancel</button>
                    <button type="button" class="btn btn-primary" @click.prevent="crop">Crop</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>