import { subscribe } from '@trullock/pubsub'
import {getCurrentUserLite, init as initAuth} from './auth.js';
import * as pageManager from '@trullock/page-manager';
import { initFirebase } from "./lib/firebase.js";

import '../pages/pages.js';
import { showConfirm } from './utils/confirm.js'

export default function() {
	initFirebase();

	// make form resets clear validation
	document.addEventListener('reset', e => {
		e.target.classList.remove('was-validated');
	}, true)
	
	// clear custom validation
	document.addEventListener('input', e => {
		let $form = e.target.closest('form');
		$form?.$('input, textarea, select').setCustomValidity('')
	}, true)
	
	document.addEventListener('input', e => {
		if(e.target.matches(':invalid')){
			const $formGroup = e.target.closest('.form-group');
			if(!$formGroup)
				return;

			const $feedback = $formGroup.querySelector('.invalid-feedback');
			if(!$feedback)
				return;

			$feedback.textContent = e.target.validationMessage;
		}
	});

	let $firstInvalidElem = null;
	document.addEventListener('invalid', e => {
		
		const $formGroup = e.target.closest('.form-group');
		if(!$formGroup)
			return;

		const $feedback = $formGroup.querySelector('.invalid-feedback');
		if(!$feedback)
			return;

		e.preventDefault();

		$feedback.textContent = e.target.validationMessage;

		let $form = e.target.closest('form');
        $form.classList.add('was-validated');

		if($firstInvalidElem == null)
		{
			$firstInvalidElem = e.target;
			setTimeout(e => {
				$firstInvalidElem.focus();
				$firstInvalidElem = null;
			}, 10);
		}

	}, true);

	var authReady = initAuth();

	document.addEventListener('click', e => {
		if(e.target.matches('a.disabled'))
		{
			e.preventDefault();
			e.stopImmediatePropagation();
			e.stopPropagation();
		}
	});

	// Password reveal
	document.addEventListener('click', e => {
		var $btn = e.target.matches('.js-reveal') ? e.target : e.target.closest('.js-reveal');
		if(!$btn)
			return;

		e.preventDefault();

		var $input = $btn.closest('.input-group').querySelector('input');
		var $eye = $btn.$('.fe');

		if($input.type == 'password'){
			$input.type = 'text';
			$eye.classList.remove('fe-eye');
			$eye.classList.add('fe-eye-off');
		} else {
			$input.type = 'password';
			$eye.classList.add('fe-eye');
			$eye.classList.remove('fe-eye-off');
		}

		$input.focus();
	});
	
	window.addEventListener('DOMContentLoaded', function(){

		authReady.then(() => pageManager.init({
			fetchPageTemplate: route => {
				if(route.pageClass.fetchHTML === false)
				{
					var $div = document.createElement('div');
					$div.className = 'd-none pg';
					return Promise.resolve($div);
				}
		
				return fetch(`/pages/app/${route.routeName}.html`)
					.then(r => r.text())
					.then(html => {
						var $div = document.createElement('div');
						$div.innerHTML = html;
						// Pages are assumed to have a single wrapping element
						return $div.firstElementChild;
					});
			},
			pageInterrupt: route => {
				if (route.pageClass.requireAuth)
				{
					if(!getCurrentUserLite())
						return { url: pageManager.getPath('account-sign-in') };
					else if (route.pageClass.requireAdmin && !getCurrentUserLite().isAdmin)
						return { url: pageManager.getPath('account-forbidden') };
					else if (route.pageClass.requireEmailVerified && !getCurrentUserLite().emailVerified)
						return { url: pageManager.getPath('account-email-verify') };
					else if (route.pageClass.requireCompleteAccount && !getCurrentUserLite().isComplete)
						return { url: pageManager.getPath('account-complete') };
					else if (route.pageClass.requireMembership && getCurrentUserLite().membership.status != 'active')
						return { url: pageManager.getPath('membership') };
				}
				
				return null;
			},
			loadingPageName: 'loading',
			defaultPageName: 'root',
			error404PageName: 'error-404',
			beforeHide: message => showConfirm({
				title: 'Confirm',
				message: message,
				buttons: [
					{
						text: 'Cancel',
						action: () => false
					},
					{
						text: 'I\'m sure',
						style: 'primary',
						action: () => true
					}
				]
			})
		}));
 
		// listen for navigations
		document.addEventListener('click', e => {
			if(e.ctrlKey || e.metaKey)
				return;

			var $a = e.target.matches('a') ? e.target : e.target.closest('a');
			if(!$a)
				return;

			if($a.target || $a.protocol == "mailto:" || $a.protocol == 'tel:')
				return;

			var href = $a.pathname + $a.search + $a.hash;
			if(href == '')
				return;
				
			pageManager.navigate(href, {}, true)

			e.preventDefault();
		}, false);

		// user state classes
		subscribe('user changed', (user) => {
			var $anons = document.$('.user-anon');
			var $autheds = document.$('.user-authed');
			var $admins = document.$('.user-admin');
			
			if(user){
				$anons?.classList.add('d-none');
				$autheds?.classList.remove('d-none');
				$admins?.classList.toggle('d-none', !user.isAdmin);
			} else {
				$anons?.classList.remove('d-none');
				$autheds?.classList.add('d-none');
				$admins?.classList.add('d-none');
			}

			var $avatar = document.$('.js-user-avatar');
			$avatar.src = user?.avatar || '';
		})

		let $nav = document.body.$('nav.js-nav');
		document.addEventListener('page-shown', e => {
			if(e.detail.page.constructor.furniture == 'plain')
			{
				document.body.classList.add('footer-offset', 'd-flex', 'align-items-center', 'bg-auth', 'border-top', 'border-top-2', 'border-primary')
				$nav.classList.add('d-none')
			}
			else
			{
				document.body.classList.remove('footer-offset', 'd-flex', 'align-items-center', 'bg-auth', 'border-top', 'border-top-2', 'border-primary')
				$nav.classList.remove('d-none')
			}
		});

		// dropdown menus
		document.addEventListener('click', e => {

			var $button = e.target.matches('.dropdown-toggle') ? e.target : e.target.closest('.dropdown-toggle');
			var $menu = $button && $button.parentElement.$('.dropdown-menu')[0];

			document.$('.dropdown-menu').forEach($m => $m != $menu && $m.classList.remove('show'));
			
			if ($menu)
				{
					e.preventDefault();
	
					if($menu.className.indexOf('show') > -1)
					{
						$menu.classList.remove('show');
					} else {
						$menu.classList.add('show');
	
						var top = $button.offsetTop + $button.offsetHeight;
						$menu.style.top = (top + 0) + 'px';
	
	
						var bottom = $menu.offsetTop + $menu.offsetHeight;
						let right = $menu.offsetLeft + $menu.offsetWidth;
	
						var $el = $menu;
						while ($el.offsetParent) {
							$el = $el.offsetParent;
							bottom += $el.offsetTop;
							right += $el.offsetLeft;
						}
	
	
						if(right > window.innerWidth - 100)
						{
							$menu.style.left = 'auto'
							$menu.style.right = '0px'
						}
						else
						{
							$menu.style.left = '0px'
							$menu.style.right = 'auto'
						}
	
						if(bottom > window.scrollY + window.innerHeight)
							window.scroll(0, bottom);
					}
				}

		}, false);
	});
}
