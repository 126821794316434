import {registerClassForPersistence} from '../lib/automapper.js'

import User from './users/user.js'
import UserView from './users/userView.js';
import UserLite from './users/userLite.js';
import PendingCertificationAttempt from './users/pendingCertificationAttempt.js';
import PendingMAP2Attempt from './users/pendingMAP2Attempt.js';
import MembershipNumber from './users/membershipNumber.js';
import Rocket from './rockets/rocket.js';
import RocketView from './rockets/rocketView.js';
import UserSearch from './users/userSearch.js';
import ScoreBoard from './map/scoreboard.js';


registerClassForPersistence(User);
registerClassForPersistence(UserView);
registerClassForPersistence(UserLite);
registerClassForPersistence(UserSearch);

registerClassForPersistence(PendingCertificationAttempt);
registerClassForPersistence(PendingMAP2Attempt);

registerClassForPersistence(MembershipNumber);

registerClassForPersistence(Rocket);
registerClassForPersistence(RocketView);

registerClassForPersistence(ScoreBoard);