<script>
import { functions } from '../../js/lib/functions.js';
import { hideLoading, showLoading } from '../../js/utils/loading.js';

export const pageName = 'admin-update';
export const pageRoute = '/admin/update';
export const title = 'System Updates'
export const requireAdmin = true;

export default {
	data() {
		return { 
			ready: true
		}
	},

	methods: {
		update: async function(e) {
			
			showLoading();

			let result = await functions.update();

			hideLoading();
			
		}
	},
	props: [ 'options' ]
}

</script>
<template>
	<div v-if="ready" class="container py-5">
		<div class="row">

			<div class="col">
				<div class="card">
					<div class="card-body">
						<form @submit.prevent="update">
							<button type="submit" class="btn btn-primary">Run Update Function</button>
						</form>		
					</div>
				</div>
			</div>
		</div>
    </div>
</template>
