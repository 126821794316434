<script>
export const pageName = 'certification-level-1';
export const pageRoute = '/certification/level-1';
export const title = 'Level 1 Certification'
export const requireMembership = true;
export const requireCompleteAccount = true;

import Modal from '../../js/utils/modal.js';
import Cropper from 'cropperjs'

import { getCurrentUserId } from '../../js/auth.js';
import { functions } from '../../js/lib/functions.js';
import { navigate } from '@trullock/page-manager'
import { showToast } from '../../js/utils/toast.js'
import UserView from '../../../functions/domain/users/userView.js';
import DateTime from '../../../functions/lib/dateTime.js';
import { hideLoading, showLoading } from '../../js/utils/loading.js';
import { listenToProjection } from '../../js/lib/client-read-model.js';
import { getMembershipImportDate } from '../../../functions/src/site.config.js'

export default {
	data() {
		return {
			currentUser: null,
			
			minDate: new DateTime(2024, 1, 1),
			maxDate: DateTime.today,

			flownOn: null,
			location: null,
			rocketName: null,
			motorManufacturer: null,
			motorDesignation: null,
			rocketDiameter: null,
			rocketLength: null,
			rocketCP: null,
			rocketCG: null,
			rocketSM: null,
			rocketPredictedAlt: null,
			rocketMotorDesignations: null,
			rocketMotorManufacturers: null,
			rocketType: '',
			rocketComments: null,

			flightCard: null,

			ready: false
		}
	},

	methods: {
		getMembershipImportDate,
		async boot(opts) {
			this.modal = new Modal(this.$refs.cropper);

			await listenToProjection([UserView, getCurrentUserId()], user => this.currentUser = user);
			this.ready = true;

		},
		stageBadgeText(stage)
		{
			return stage.status == 'pending' ? 'Pending' : 
				stage.status == 'passed' ? 'Passed' : 
				'Not started'
		},
		stageBadgeClass(stage)
		{
			return stage.status == 'pending' ? 'badge-secondary' : 
				stage.status == 'passed' ? 'badge-success' : 
				'badge-light'
		},
		attemptBadgeText(stage)
		{
			return stage.result == null ? 'Pending' : 
				stage.result == 'passed' ? 'Passed' : 
				'Failed'
		},
		attemptBadgeClass(stage)
		{
			return stage.result == null ? 'badge-secondary' : 
				stage.result == 'passed' ? 'badge-success' : 
				'badge-danger'
		},
		show() {
			functions.warmUp.userSubmitCertificationAttempt()
		},
		async submit() {
			
			// we have to manually validate the file upload because `required` doesnt have good UX cross browser
			if(!this.flightCard)
			{
				this.$refs.fupFlightCard.setCustomValidity('Please provide an picture of your completed flight card');
				this.$refs.fupFlightCard.reportValidity();
				return;
			}

			showLoading();

			let result = await functions.userSubmitCertificationAttempt({ 
				type: this.currentUser.certification.level1.nextStage,
				attempt: {
					flownOn: new DateTime(this.flownOn),
					location: this.location,
					name: this.rocketName,
					cg: this.rocketCG,
					cp: this.rocketCP,
					diameter: this.rocketDiameter,
					length: this.rocketLength,
					motorDesignations: this.rocketMotorDesignations,
					motorManufacturers: this.rocketMotorManufacturers,
					sm: this.rocketSM,
					predictedAlt: this.rocketPredictedAlt,
					type: this.rocketType,
					comments: this.rocketComments,
					flightCard: this.flightCard
				}
			});
			
			// reset form
			this.flownOn = null;
			this.location = null;
			this.rocketName = null;
			this.rocketCG = null;
			this.rocketCP = null;
			this.rocketDiameter = null;
			this.rocketLength = null;
			this.rocketMotorDesignations = null;
			this.rocketMotorManufacturers = null;
			this.rocketSM = null;
			this.rocketPredictedAlt = null;
			this.rocketType = '';
			this.rocketComments = null;
			this.flightCard = null;

			if(!result.success)
			{
				showToast({ message: 'Error submitting attempt', style: 'bg-danger'})
				hideLoading();
				return;
			}

			navigate('/');

			showToast({
				message: 'Certification attempt submitted',
				style: 'bg-success'
			})

			hideLoading();
		},
		crop() {
			showLoading();
			this.modal.hide();

			// use a separate "thread" because of UI locking
			setTimeout(() => {
					
				let canvas = this.cropper.getCroppedCanvas({
					
				});

				this.flightCard = canvas.toDataURL();

				canvas.toBlob(blob => {
					this.$refs.fupFlightCard.blobData = {
						blob: blob,
						name: 'flight-card.jpg'
					};
					this.$refs.lblFlightCard.innerText = this.$refs.fupFlightCard.pendingName;
				});
				
				hideLoading();
			}, 1);
		},
		hideModal() {
			this.modal.hide()
			this.cropper.destroy();
			this.cropper = null;
		},
		inputChanged(e) {
			this.$refs.fupFlightCard.setCustomValidity('');
			this.$refs.fupFlightCard.reportValidity();

			var files = e.target.files;
			var done = (url, name) => {
				this.$refs.fupFlightCard.blobData = null;

				let $img = new Image();
				
				$img.addEventListener('load', e => {
					this.$refs.fupFlightCard.innerHTML = "Choose image&hellip;";
					this.$refs.fupFlightCard.pendingName = name;
					this.$refs.imgCropper.src = url;

					if(this.cropper)
						this.cropper.destroy();

					let maxHeight = window.innerHeight - 230;
					this.$refs.imgCropper.style.maxHeight = maxHeight + 'px'
					
					this.modal.show();
					this.cropper = new Cropper(this.$refs.imgCropper, {
						viewMode: 1,
						autoCropArea: 1
					});
				})
				$img.addEventListener('error', e => {
					this.$refs.fupFlightCard.setCustomValidity('File not a recognised image');
					this.$refs.fupFlightCard.reportValidity();
				})
				$img.src = url;
			};

			if (files && files.length > 0) {
				let file = files[0];
				if (URL) {
					done(URL.createObjectURL(file), file.name);
				} else if (FileReader) {
					let reader = new FileReader();
					reader.onload = function (e) {
						done(reader.result, file.name);
					};
					reader.readAsDataURL(file);
				}
			}
		}
	},
	props: [ 'options' ]
}

</script>
<template>
	<div v-if="ready" class="container py-5">
		<div class="row">
			<div class="col col-12">
				<div class="card">
					<div class="card-header">
						<h4 class="card-header-title">Level 1 Certification</h4>
					</div>
					<div class="card-body">
						<p>Level 1 certification is required to fly rockets with a combined impulse between 'H' and 'I' class (between 160Ns and 640Ns).</p>

						<p>The member is required to perform three successful flights with three different rockets - two qualification flights and a final certification flight:</p>

						<ul>
							<li>Qualification flight 1: A single A-D impulse motor (or a cluster not exceeding D impulse in total)</li>
							<li>Qualification flight 2: A single E-G impulse motor</li>
							<li>Level 1 Certification flight: A single H-I impulse motor with the preparation, flight and recovery observed by the Flight Certification Officer (FCO)</li>
						</ul>
						
						<p>Detailed information about the <a href="https://www.ukra.org.uk/certification/high-power-certification/" target="_blank">Level 1 Certification Process <span class="fe fe-external-link"></span></a> can be found on the main UKRA website.</p>
						
						<p>Download the <a href="https://ukra.org.uk/wp-content/uploads/2024/09/flight-card.pdf" target="_blank">Flight Card <span class="fe fe-external-link"></span></a> for use in the field.</p>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col col-12 col-lg-8">
				<div class="card">
					<div class="card-header">
						<h4 class="card-header-title">{{
							currentUser.certification.level1.stage1.status != 'passed' ? 'Qualification Flight 1' : 
							currentUser.certification.level1.stage2.status != 'passed' ? 'Qualification Flight 2' : 
							'Certification Flight'
							}}</h4>
					</div>
					<div class="card-body" v-if="currentUser.certification.level1.status == 'passed'">
						<p>Congratulations! You've passed your Level 1 certification.</p>
						<p>Level 2 is the next challenge. <a href="/certification/level-2">Lets get started!</a></p>
						<p>Why not order a certificate to hang in your rocket workshop?</p>
						<a href="/certification/buy-a-certificate/level1" class="btn btn-primary btn-block">Order a certificate <span class="fe fe-award"></span></a>
					</div>
					<div class="card-body" v-else-if="!currentUser.certification.level1.isAwaitingApproval">
						<form ref="form" @submit.prevent="submit">
							<fieldset>
								<legend>Attempt details</legend>
								<div class="form-group">
									<label>Date</label>
									<input type="date" class="form-control" required v-model="flownOn" autocomplete="off" :max="`${maxDate.format('yyyy-MM-dd')}`" :min="`${minDate.format('yyyy-MM-dd')}`"/>
									<span class="invalid-feedback"></span>
								</div>
								<div class="form-group">
									<label>Location</label>
									<input type="text" class="form-control" v-model="location" autocomplete="off" required />
									<span class="invalid-feedback"></span>
								</div>
								<hr>
								<div class="form-group">
									<label>Rocket Name</label>
									<input type="text" class="form-control" v-model="rocketName" autocomplete="off" required />
									<span class="invalid-feedback"></span>
								</div>
								<div class="row">
									<div class="col">
										<div class="form-group">
											<label>Rocket Diameter</label>
											<div class="input-group has-validation">
												<input type="number" min="1" max="99999" step="1" autocomplete="off" required v-model="rocketDiameter" class="form-control" placeholder="e.g. 100" />
												<div class="input-group-append">
													<div class="input-group-text">
														mm
													</div>
												</div>
												<span class="invalid-feedback"></span>
											</div>
										</div>
									</div>
									<div class="col">
										<div class="form-group">
											<label>Rocket Length</label>
											<div class="input-group has-validation">
												<input type="number" min="1" max="99999" step="1" autocomplete="off" required v-model="rocketLength" class="form-control" placeholder="e.g. 100" />
												<div class="input-group-append">
													<div class="input-group-text">
														mm
													</div>
												</div>
												<span class="invalid-feedback"></span>
											</div>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col">
										<div class="form-group">
											<label>Center of Pressure</label>
											<div class="input-group has-validation">
												<input type="number" min="1" max="99999" step="1" autocomplete="off" required v-model="rocketCP" class="form-control" placeholder="e.g. 900" />
												<div class="input-group-append">
													<div class="input-group-text">
														mm
													</div>
												</div>
												<span class="invalid-feedback"></span>
											</div>
											<p class="form-text text-muted small mb-0">Measured from tip of nose.</p>
										</div>
									</div>
									<div class="col">
										<div class="form-group">
											<label>Center of Mass</label>
											<div class="input-group has-validation">
												<input type="number" min="1" max="99999" step="1" autocomplete="off" required v-model="rocketCG" class="form-control" placeholder="e.g. 800" />
												<div class="input-group-append">
													<div class="input-group-text">
														mm
													</div>
												</div>
												<span class="invalid-feedback"></span>
											</div>
											<p class="form-text text-muted small mb-0">Measured from tip of nose.</p>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col">
										<div class="form-group">
											<label>Stability Margin</label>
											<input type="number" min="0.1" max="100" step="0.1" autocomplete="off" required v-model="rocketSM" class="form-control" placeholder="e.g. 1.1" />
												<span class="invalid-feedback"></span>
										</div>
									</div>
									<div class="col">
										<div class="form-group">
											<label>Predicted Altitude</label>
											<div class="input-group has-validation">
												<input type="number" min="1" max="99999" step="1" autocomplete="off" required v-model="rocketPredictedAlt" class="form-control" placeholder="e.g. 3000" />
												<div class="input-group-append">
													<div class="input-group-text">
														feet
													</div>
												</div>
												<span class="invalid-feedback"></span>
											</div>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col">
										<div class="form-group">
											<label>Motor Manufacturer(s)</label>
											<input type="text" v-model="rocketMotorManufacturers" autocomplete="off" class="form-control" placeholder="e.g. Cesaroni" required />
											<span class="invalid-feedback"></span>
										</div>
									</div>
									<div class="col">
										<div class="form-group">
											<label>Motor Designation(s)</label>
											<input type="text" v-model="rocketMotorDesignations" autocomplete="off" class="form-control" placeholder="e.g. G80SK" required />
											<span class="invalid-feedback"></span>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col">
										<div class="form-group">
											<label>Build Type</label>
											<select v-model="rocketType" class="form-control" required>
												<option value="" disabled selected>Choose...</option>
												<option value="Kit">Kit</option>
												<option value="Modified Kit">Modified Kit</option>
												<option value="Scratch Build">Scratch Built</option>
												<option value="Other">Other/Unknown</option>
											</select>
											<span class="invalid-feedback"></span>
										</div>
									</div>
									<div class="col">
									</div>
								</div>
								<div class="form-group">
									<label for="">Comments</label>
									<textarea v-model="rocketComments" placeholder="" autocomplete="off" rows="5" class="form-control"></textarea>
									<span class="invalid-feedback"></span>
								</div>
							</fieldset>
							
							<hr>
							<fieldset>
								<div class="field form-group">
									<label>
										Upload a picture of the signed flight card
										<div class="dz-default dz-message py-3" v-if="flightCard">
											<div class="avatar avatar-xxl">
												<img class="avatar-img" :src="flightCard" alt="Flight Card">
											</div>
										</div>
									</label>
									<div class="custom-file">
										<input type="file" @change="inputChanged" ref="fupFlightCard" class="custom-file-input" name="flight-card" accept="image/*;capture=camera">
										<label ref="lblFlightCard" class="custom-file-label text-muted">Choose image&hellip;</label>
										<span class="invalid-feedback"></span>
									</div>
									<span class="form-text text-muted small mb-0">Must be a jpeg or png</span>
								</div>
							</fieldset>
							<button type="submit" class="btn btn-lg btn-primary btn-block">Submit attempt <span class="fe fe-check-circle"></span></button>
						</form>
					</div>
					<div class="card-body" v-else>
						<p class="mb-0">Please wait whilst UKRA validates your attempt. You should hear back soon.</p>
					</div>
				</div>
			</div>
			<div class="col col-12 col-lg-4">
				<div class="card">
					<div class="card-header">
						<h4 class="card-header-title">Progress</h4>
					</div>
					<div class="card-body">
						<div class="text-secondary small mb-4">Complete all three flights for Level 1.</div>
						<div v-if="currentUser.certification.level1.grantedOn && currentUser.certification.level1.grantedOn < getMembershipImportDate()" class="alert alert-light small mb-4"><span class="fe fe-alert-triangle"></span> You achieved Level 1 by the previous certification process, so your progress will show as incomplete.</div>
						<div v-else-if="currentUser.certification.level1.grantedOn" class="alert alert-light small mb-4"><span class="fe fe-alert-triangle"></span> You were granted Level 1 outside of the normal process, so your certification progress may show as incomplete.</div>
						<div class="list-group list-group-flush my-n3">
							<div class="list-group-item">
								<div class="row">
									<div class="col small">
										Qualification Flight 1
									</div>
									<div class="col-auto">
										<div :class="`badge ${stageBadgeClass(currentUser.certification.level1.stage1)}`">
											{{ stageBadgeText(currentUser.certification.level1.stage1) }}
										</div>
									</div>
								</div>
							</div>
							<div class="list-group-item">
								<div class="row">
									<div class="col small">
										Qualification Flight 2
									</div>
									<div class="col-auto">
										<div :class="`badge ${stageBadgeClass(currentUser.certification.level1.stage2)}`">
											{{ stageBadgeText(currentUser.certification.level1.stage2) }}
										</div>
									</div>
								</div>
							</div>
							<div class="list-group-item">
								<div class="row">
									<div class="col small">
										Certification Flight
									</div>
									<div class="col-auto">
										<div :class="`badge ${stageBadgeClass(currentUser.certification.level1.stage3)}`">
											{{ stageBadgeText(currentUser.certification.level1.stage3) }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="card">
					<div class="card-header">
						<h4 class="card-header-title">History</h4>
					</div>
					<div class="card-body">
						<div class="list-group list-group-flush my-n3" v-if="currentUser.certification.level1.stage1.attempts.length || currentUser.certification.level1.stage2.attempts.length || currentUser.certification.level1.stage3.attempts.length">
							<div class="list-group-item" v-for="attempt in currentUser.certification.level1.stage1.attempts.sort((a,b) => a.submittedOn - b.submittedOn)">
								<div class="row">
									<div class="col small">
										<h5>Prequalification Flight 1</h5>
										<span class="small text-muted">{{ attempt.submittedOn.format('yyyy/MM/dd HH:mm') }}</span>
									</div>
									<div class="col-auto">
										<div :class="`badge ${attemptBadgeClass(attempt)}`">
											{{ attemptBadgeText(attempt) }}
										</div>
									</div>
								</div>
							</div>
							<div class="list-group-item" v-for="attempt in currentUser.certification.level1.stage2.attempts.sort((a,b) => a.submittedOn - b.submittedOn)">
								<div class="row">
									<div class="col small">
										<h5>Prequalification Flight 2</h5>
										<span class="small text-muted">{{ attempt.submittedOn.format('yyyy/MM/dd HH:mm') }}</span>
									</div>
									<div class="col-auto">
										<div :class="`badge ${attemptBadgeClass(attempt)}`">
											{{ attemptBadgeText(attempt) }}
										</div>
									</div>
								</div>
							</div>
							<div class="list-group-item" v-for="attempt in currentUser.certification.level1.stage3.attempts.sort((a,b) => a.submittedOn - b.submittedOn)">
								<div class="row">
									<div class="col small">
										<h5>Final Flight</h5>
										<span class="small text-muted">{{ attempt.submittedOn.format('yyyy/MM/dd HH:mm') }}</span>
									</div>
									<div class="col-auto">
										<div :class="`badge ${attemptBadgeClass(attempt)}`">
											{{ attemptBadgeText(attempt) }}
										</div>
									</div>
								</div>
							</div>
						</div>
						<p class="small text-muted mb-0" v-else>You have not previously submitted any L1 certification attempts.</p>
					</div>
				</div>
			</div>
		</div>
    </div>
	
<div class="modal fade" ref="cropper" tabindex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="card mb-0">
                <div class="card-header">
                    <h4 class="card-header-title">Crop the image</h4>
                    <button type="button" class="close" @click.prevent="hideModal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="card-body">
					<p>Your picture must be good quality and clearly legible.</p>
                    <div class="img-container">
                        <img ref="imgCropper" :src="flightCard" class="">
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click.prevent="hideModal">Cancel</button>
                    <button type="button" class="btn btn-primary" @click.prevent="crop">Crop</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
