export default class Page {
	
	#title = null;
	#visible = false;

	constructor($page){
		this.$page = $page;
	}


	get title() {
		return this.#title || this.$page.dataset['title'] || 'UKRA'
	}

	set title(value) {
		this.#title = value;
	}

	boot(opts){
		return Promise.resolve();
	}

	update(data) {
		return Promise.resolve();
	}

	show(opts) {
		if(!this.#visible)
		{
			this.$page.classList.remove('d-none');
			this.$page.offsetWidth;

			let y = 0;
			// BUG doesnt wait until page ready (vue hack)
			if(opts.route.params.hash)
			{
				var $elem = this.$page.$('#' + opts.route.params.hash);
				if($elem)
					y = $elem.offset().top - 30;
			}

			window.scroll(0, opts.scrollY || y);

			this.#visible = true;
			this.$page.$('[autofocus]:not([disabled])')?.focus();

			this.$page.dispatchEvent(new CustomEvent('page-shown', {
				bubbles: true, detail: {
					$page: this.$page,
					page: this,
					opts: opts
				}
			}))
		}

		return Promise.resolve(true);
	}

	async hide() {
		if (this.#visible)
		{
			this.$page.classList.add('d-none');
			this.#visible = false;
			
			// This messes up Vue
			// Reset forms			
			// [...this.$page.querySelectorAll('form')].forEach($form => {
			// 	$form.reset()
			// });

			this.$page.$('.was-validated')?.classList.remove('was-validated');

			this.$page.dispatchEvent(new CustomEvent('page-hidden', {
				bubbles: true, detail: {
					$page: this.$page,
					page: this
				}
			}));
		}

		return Promise.resolve(true);
	}
}