export default class PendingMAP2Attempt
{
	id = null
	submittedOn = null

	userId = null
	userName = null
	
	rocketId = null
	flightId = null
}