<script>
import { functions } from '../../js/lib/functions.js'
import { navigate } from '@trullock/page-manager'
import { hideLoading, showLoading } from '../../js/utils/loading.js';
import { showToast } from '../../js/utils/toast.js';
import { showConfirm } from '../../js/utils/confirm.js'

export const pageName = 'admin-email-members';
export const pageRoute = '/admin/email-members';
export const title = 'Email Members'
export const requireAdmin = true;

export default {
	data() {
		return { 
			user: null,
			subject: '',
			recipients: 'active',
			message: '',
			ready: false
		}
	},
	methods: {
		async submit() {			
			showLoading();

			let result = await functions.emailMembers({
				subject: this.subject,
				message: this.message,
				recipients: this.recipients
			}, { timeout: 1000 * 60 * 60})
			
			if(!result.success)
			{
				showToast({
					message: 'Error sending email, contact system administrator',
					style: 'bg-danger'
				})
				return;
			}

			navigate('/admin')

			showToast({
				message: 'Sent to ' + result.recipients + ' people',
				style: 'bg-success'
			})

			hideLoading();
		},
		async sendTest() {			
			showLoading();

			let result = await functions.emailMembers({
				subject: this.subject,
				message: this.message,
				recipients: 'test'
			}, { timeout: 1000 * 60 * 60})
			
			if(!result.success)
			{
				showToast({
					message: 'Error sending test email, contact system administrator',
					style: 'bg-danger'
				})
				return;
			}

			showToast({
				message: 'Sent to you',
				style: 'bg-success'
			})

			hideLoading();
		},
		async count() {			
			showLoading();

			let result = await functions.emailMembersCount({
				recipients: this.recipients
			})
			
			hideLoading();

			showConfirm({
				title: 'Query results',
				message: `You would be emailing ${result.recipients} people.`,
				dismissable: false,
				buttons: [
					{
						text: 'Got it',
						style: 'primary'
					}
				]
			})
		},
		async show(opts)
		{
			functions.warmUp.emailMembers();
			this.ready = true;
		}
	},
	props: [ 'options' ]
}
</script>
<template>
<div class="container" v-if="ready">
    <form @submit.prevent="submit">
		<div class="row justify-content-center py-5">
			<div class="col-12 col-md-8">
				
				<div class="card">
					<div class="card-header">
						<h4 class="card-header-title">Email members</h4>
					</div>
					<div class="card-body">
						<fieldset>
							<legend>Message</legend>
							<div class="form-group mb-4">
								<label>Recipients</label>
								<select class="form-control" v-model="recipients">
									<option value="all">All registered users</option>
									<option value="active">Active members</option>
									<option value="expiring">Expiring members</option>
									<option value="expired">Expired members</option>
									<option value="rsos">RSOs</option>
									<option value="l1s">HPR L1s</option>
									<option value="l2s">HPR L2s</option>
									<option value="l3s">HPR L3s</option>
								</select>
								<span class="invalid-feedback"></span>
							</div>
							<div class="form-group mb-4">
								<label>Subject</label>
								<input type="text" class="form-control" v-model="subject" autocomplete="off" required />
								<span class="invalid-feedback"></span>
							</div>
							<div class="form-group mb-0">
								<label>Message</label>
								<textarea class="form-control" autocomplete="off" v-model="message"></textarea>
								<span class="invalid-feedback"></span>
							</div>
						</fieldset>
					</div>
				</div>
				<div class="alert alert-light small"><span class="fe fe-info"></span> Sending may take a while depending on the number of recipients, please be patient.</div>
				<button type="button" @click.prevent="count" class="btn btn-lg btn-outline-primary btn-block">Count Recipients <span class="fe fe-users"></span></button>
				<button type="button" @click.prevent="sendTest" class="btn btn-lg btn-primary btn-block">Send test to me <span class="fe fe-send"></span></button>
				<button type="submit" class="btn btn-lg btn-primary btn-block">Send Email <span class="fe fe-send"></span></button>
			</div>
		</div>
	</form>
</div>
</template>