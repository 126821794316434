import { getFirestore, collection, getDocs, query, where, collectionGroup } from 'firebase/firestore'
import { chunk } from '../../functions/lib/arrayFunctions.js'
import '../../functions/domain/mappings.js'
import UserView from '../../functions/domain/users/userView.js';
import UserLite from '../../functions/domain/users/userLite.js';
import { explainError } from '../../functions/lib/persistence.js';
import { findProjection, findProjections, getProjection, getProjections, getProjectionsById, listenForFoundProjections, listenToProjection, listenToSingletonProjection } from './lib/client-read-model.js';
import { convertFromFirestore } from '../../functions/lib/automapper.js';
import PendingCertificationAttempt from '../../functions/domain/users/pendingCertificationAttempt.js';
import PendingMAP2Attempt from '../../functions/domain/users/pendingMAP2Attempt.js';
import UserSearch from '../../functions/domain/users/userSearch.js';
import RocketView from '../../functions/domain/rockets/rocketView.js';
import ScoreBoard from '../../functions/domain/map/scoreboard.js';


export function getUserView(id)
{
	return getProjection([UserView, id]);
}

export async function getUserViewByEmail(email)
{
	return findProjection([UserView], where("email", "==", email))
}

export async function getUserViewByNumber(number)
{
	return findProjection([UserView], where("number", "==", number))
}

export async function searchUserViewsByName(name)
{
	let results = await findProjections([UserSearch], where("keywords", "array-contains", name.toLowerCase()));
	return getProjectionsById([UserLite], results.map(r => r.id));
}

export function getUserLite(id)
{
	return getProjection([UserLite, id]);
}

export async function getUserLites(userIds)
{
	if(userIds.length == 0)
		return [];

	let db = getFirestore();
	let ref = collection(db, 'UserLite');

	let idChunks = chunk(userIds, 10);
	// TODO: try catch
	let chunkedSnapshots = await Promise.all(idChunks.map(ids => getDocs(query(ref, where('id', 'in', ids)))))
	let users = chunkedSnapshots.flatMap(snap => snap.docs.map(doc => convertFromFirestore(doc.data())))
	return users;
}

export async function getUserEvents(userId)
{
	let db = getFirestore();
	let ref = collection(db, `User/${userId}/UserEvents`);
	let snap = null;
	try
	{
		// TODO: batch read
		snap = await getDocs(ref);
	}
	catch(e)
	{
		throw explainError(e, `Failed to get UserEvents for user ${userId}`);
	}

	let events = snap.docs.map(doc => {
		let event = convertFromFirestore(doc.data())
		event.occurredOn = event.data.occurredOn;
		event.issuingUserId = event.data.issuingUserId;
		delete event.data.aggregateRootId
		delete event.data.occurredOn
		delete event.data.issuingUserId;
		return event;
	})
	return events;
}

export async function getUserRocketEvents(userId)
{
	let db = getFirestore();
	let ref = query(collectionGroup(db, `RocketEvents`), where('data.ownerId', '==', userId));
	
	let snap = null;
	try
	{
		// TODO: batch read
		snap = await getDocs(ref);
	}
	catch(e)
	{
		throw explainError(e, `Failed to get RocketEvents for user ${userId}`);
	}

	let events = snap.docs.map(doc => {
		let event = convertFromFirestore(doc.data())
		event.occurredOn = event.data.occurredOn;
		event.issuingUserId = event.data.issuingUserId;
		event.data.id = event.data.aggregateRootId;
		delete event.data.aggregateRootId
		delete event.data.occurredOn
		delete event.data.issuingUserId;
		delete event.data.ownerId
		return event;
	})
	return events;
}

export function listenToUserView(userId, callback)
{
	return listenToProjection([UserView, userId], callback);
}

export function listenToUserPendingAttempts(userId, array)
{
	return listenForFoundProjections([PendingCertificationAttempt], where("userId", "==", userId), array);
}

export function listenToUserPendingMAPAttempts(userId, array)
{
	return listenForFoundProjections([PendingMAP2Attempt], where("userId", "==", userId), array);
}

export function getPendingMAP2Attempt(attemptId)
{
	return getProjection([PendingMAP2Attempt, attemptId]);
}

export function getRocketView(userId, rocketId)
{
	return getProjection([UserView, userId, RocketView, rocketId]);
}


export function listenToMapScoreboard(callback)
{
	return listenToSingletonProjection(ScoreBoard, callback);
}
