<script>
export const pageName = 'map-2';
export const pageRoute = '/model-achievement-programme';
export const title = 'MAP v2'
export const requireAuth = false

import { getMap } from '../../../functions/domain/map/map.js';
import { getCurrentUserId } from '../../js/auth.js';
import { listenToMapScoreboard, listenToUserView } from '../../js/readModel.js';
import { showConfirm} from '../../js/utils/confirm.js'
export default {
	data() {
		return {
			currentUser: null,
			data: null,
			scoreboard: null,
			backgrounds: {},
			ready: false
		}
	},

	methods: {
		async boot(opts) {

			// allow anon visitors from main website
			if(getCurrentUserId())
				await listenToUserView(getCurrentUserId(), user => this.currentUser = user);

			let data = getMap(this.currentUser);

			await listenToMapScoreboard(scoreboard => this.scoreboard = scoreboard);


			await Promise.all(data.levels.map(async l => {
				await Promise.all(l.subjects.map(async s => {
					await Promise.all(s.tasks.map(async t => {
						
						let img = await import(`../../img/map/${t.bgColor}.png`)
						t.bg = img.default

						this.backgrounds[t.bgColor] = img.default
						
						img = await import(`../../img/map/${t.taskId.replaceAll('/', '_').toLowerCase()}.png`)
						t.icon = img.default;
					}))
				}))
			}))

			await Promise.all(data.badges.map(async b => {
				
				let img = await import(`../../img/map/${b.bgColor}.png`)
				b.bg = img.default
				
				img = await import(`../../img/map/${b.taskId.replaceAll('/', '_').toLowerCase()}.png`)
				b.icon = img.default;
			}))

			this.data = data;

			this.ready = true;
		},
		showTask(task)
		{
			showConfirm({
				title: task.desc,
				message: task.rules?.join('<br />'),
				buttons: [
					{
						text: 'Close',
						style: 'primary'
					}
				]
			})
		},
		show() {
		}
	},
	props: [ 'options' ]
}

</script>
<template>
	<div v-if="ready" class="container py-5">
		<h1 class="display-4 text-center mb-3">Model Achievement Programme</h1>
		<p class="text-muted text-center mb-5">The Model Achievement Programme is a fantastic opportunity to develop your skills as a rocket modeller by completing a series of increasingly difficult and complicated challenges. Please see <a href="https://ukra.org.uk/certification/model-achievement-programme/" target="_blank">the main UKRA website <span class="fe fe-external-link"></span></a> for a full description of the programme.</p>
		
		<div class="alert alert-warning small">
			<p><span class="fe fe-alert-triangle"></span> MAP is not live until 2025, however we have published a draft version here for you to start considering your builds and adding to your Christmas wish list.</p>
			<p class="mb-0">Whilst this is a draft and is largely stable, it is subject to change at any point upto 2025/1/1.</p>
		</div>
		
		<h1 class="mb-4 text-center">Leader board</h1>
		<div class="row">
			<div class="col-12 col-md-6">
				<div class="card">
					<div class="card-header">
						<h4 class="card-header-title">XP</h4>
					</div>
					<div class="card-body">
						<div class="list-group list-group-flush my-n3">
							<div v-for="(xper, index) in scoreboard.xpScoreboard" class="list-group-item">
								<div class="row align-items-center">
									<div class="col-auto">
										<span class="display-4">{{ index + 1}}</span>
									</div>	
									<div class="col-auto">
										<div class="avatar avatar-sm">
											<img class="avatar-img rounded-circle" :src="xper.userAvatar">
										</div>
									</div>
									<div class="col">
										<h4 class="mb-1">{{ xper.userName }}</h4>
										<small class="text-muted">{{ xper.xp  }} XP</small>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 col-md-6">
				<div class="card">
					<div class="card-header">
						<h4 class="card-header-title">Hydrazine</h4>
					</div>
					<div class="card-body">
						<div class="list-group list-group-flush my-n3">
							<div v-for="(xper, index) in scoreboard.hydrazineScoreboard" class="list-group-item">
								<div class="row align-items-center">
									<div class="col-auto">
										<span class="display-4">{{ index + 1}}</span>
									</div>	
									<div class="col-auto">
										<div class="avatar avatar-sm">
											<img class="avatar-img rounded-circle" :src="xper.userAvatar">
										</div>
									</div>
									<div class="col">
										<h4 class="mb-1">{{ xper.userName }}</h4>
										<small class="text-muted">{{ xper.hydrazine  }} Hydrazine</small>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="text-center" v-for="(level, i) in data.levels">
			<hr class="my-5">
			<h1 class="mb-2">{{ level.name }}</h1>
			<p v-if="level.xpRequired" class="text-muted mb-5">You need {{ level.xpRequired }} XP to graduate from this rank.</p>
			<p v-else class="text-muted mb-5">This is the highest rank within MAP.</p>
			<div class="row">
				<template v-for="subject of data.levels[i].subjects">
					<template v-if="subject.tasks.length">
						<div v-for="task in subject.tasks" class="col-12 col-sm-6 col-md-4 col-lg-3">
							<a :href="`#${task.id}`" :id="task.id" @click.prevent="showTask(task)" class="card card-fill card-fill-top">
								<div class="card-body">
									<div class="d-inline-block" :style="`background: url(${task.bg}); ${task.mode == 'future' ? 'opacity: 0.5' : ''}`">
										<img :src="task.icon" />
									</div>
									<h2 :class="`${task.mode == 'future' ? 'text-muted' : 'text-body'}`">{{ task.name }}</h2>
									<div class="text-muted">{{ task.desc }}</div>
									<span v-if="task.xp && !task.complete" :class="`badge badge-white border border-1 shadow font-weight-bold`" style="position: absolute; top: -5px; right: -5px; font-size: 90%">+{{ task.xp }} XP</span>
									<span v-if="task.xp && task.complete" :class="`badge badge-success border border-1 shadow font-weight-bold`" style="position: absolute; top: -5px; right: -5px; font-size: 90%">Complete</span>
									<span v-if="task.hydrazine" :class="`badge ${task.mode == 'future' ? 'badge-white' : 'badge-soft-danger'} shadow font-weight-bold`" style="position: absolute; top: -5px; right: -5px; font-size: 90%">+{{ task.hydrazine }} Hydrazine</span>
								</div>
							</a>
						</div>
					</template>
				</template>
			</div>
		</div>
    </div>
</template>
