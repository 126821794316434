import AggregateRoot from "../../lib/aggregateRoot.js"
import { v4 as newId } from "uuid";
import DateTime from "../../lib/dateTime.js";
import { getLevelForXP } from "../map/map.js";

export default class User extends AggregateRoot
{
	createdOn = null
	number = null
	name = null
	BMFANumber = null
	BMFASurname = null
	email = null
	bornOn = null
	avatar = null
	avatarChanged = false
	isAdmin = false
	isImported = false
	accountComplete = false;
	certification = new User.Certification()
	rso = new User.RSO();
	map2 = new User.MAP2()
	membership = new User.Membership()

	static Certification = class {

		attempts = []

		level1 = false
		level2 = false
		level3 = false

		static Attempt = class {
			id = null
			type = null
			submittedOn = null
			status = null
		}
	}

	static RSO = class {
		level = null
		mode = null
	}

	static MAP2 = class {

		attemptPending = false
		xp = 0
		hydrazine = 0
		level = 'recruit'
	}

	static Membership = class {
		number = null
		startedOn = null
		expiresOn = null
		
		get status()
		{
			if(this.startedOn == null)
				return 'non-member'
			
			if(this.expiresOn != null && this.expiresOn < DateTime.now)
				return 'expired'

			return 'active';
		}
	}

	static create(issuingUserId, createdOn, id, number, name, email, avatarUrl, realAvatar, emailVerified)
	{
		let user = new User();
		user.#create(issuingUserId, createdOn, id, number, name, email, avatarUrl, realAvatar, emailVerified);
		return user;
	}

	#create(issuingUserId, createdOn, id, number, name, email, avatar, realAvatar, emailVerified)
	{
		email = email.toLowerCase();

		this.apply('userCreated', {
			occurredOn: createdOn,
			issuingUserId,
			aggregateRootId: id,
			number,
			name, 
			email,
			avatar,
			realAvatar,
			emailVerified
		});
	}

	onUserCreated(event) 
	{
		this.id = event.aggregateRootId;
		this.number = event.number;
		this.name = event.name;
		this.email = event.email;
		this.avatar = event.avatar;
		this.emailVerified = event.emailVerified;
	}

	emailVerify(issuingUserId) {
		if(this.emailVerified)
			return;

		this.apply('userEmailVerified', { issuingUserId })
	}

	onUserEmailVerified(event) {
		this.emailVerified = true;
	}

	changeEmail(issuingUserId, email)
	{
		email = email.toLowerCase();

		if(this.email == email)
			return;

		this.apply('userEmailChanged', { 
			issuingUserId,
			email
		})
	}

	onUserEmailChanged(event) {
		this.email = event.email;
		this.emailVerified = false;
	}

	changeName(issuingUserId, name){
		if(name == this.name)
			return;

		this.apply('userNameChanged', { 
			issuingUserId,
			name
		})
	}

	onUserNameChanged(event){
		this.name = event.name;
	}

	changeBornOn(issuingUserId, bornOn){
		if(!bornOn instanceof DateTime)
			throw new Error('BornOn must be a DateTime');

		if(bornOn < DateTime.today.addYears(-120) || bornOn > DateTime.today)
			throw new Error('BornOn out of range');
		
		if(bornOn.equals(this.bornOn))
			return;

		this.apply('userBornOnChanged', { 
			issuingUserId, 
			bornOn
		})
	}

	onUserBornOnChanged(event){
		this.bornOn = event.bornOn;
	}

	changeBMFA(issuingUserId, number, surname){
		if(number == this.BMFANumber && surname == this.BMFASurname)
			return;

		this.apply('userBMFAChanged', { 
			issuingUserId, 
			number,
			surname
		})
	}

	onUserBMFAChanged(event){
		this.BMFANumber = event.number;
		this.BMFASurname = event.surname;
	}

	changeNumber(issuingUserId, number){
		if(number == this.number)
			return;

		this.apply('userNumberChanged', { 
			issuingUserId, 
			number
		})
	}

	onUserNumberChanged(event){
		this.number = event.number;
	}

	changeCreatedOn(issuingUserId, createdOn)
	{
		// this property didnt historically exist
		if(this.createdOn && this.createdOn.equals(createdOn))
			return;

		this.apply('userCreatedOnChanged', {
			issuingUserId,
			createdOn
		})
	}

	changeAvatar(issuingUserId, path){
		if(path == this.avatar)
			return;

		this.apply('userAvatarChanged', { 
			issuingUserId, 
			path
		})
	}

	onUserAvatarChanged(event){
		this.path = event.path;
	}


	/// CERTIFICATION
	/////////////////////////////////////////////////////////

	submitCertificationAttempt(type, attempt)
	{
		let existingAttempts = this.certification.attempts.filter(f => f.type == type);
		
		if(existingAttempts.some(f => f.status == 'pending'))
			throw new Error("Pending attempt already exists");

		if(existingAttempts.some(f => f.status == 'passed'))
			throw new Error("Approved attempt already exists");

		this.apply('userSubmittedCertificationAttempt', {
			issuingUserId: this.id,
			userName: this.name,
			type,
			attempt: {
				id: newId(),
				flownOn: attempt.flownOn,
				location: attempt.location,
				name: attempt.name,
				cg: attempt.cg,
				cp: attempt.cp,
				diameter: attempt.diameter,
				length: attempt.length,
				motorDesignations: attempt.motorDesignations,
				motorManufacturers: attempt.motorManufacturers,
				sm: attempt.sm,
				predictedAlt: attempt.predictedAlt,
				type: attempt.type,
				comments: attempt.comments,
				flightCardUrl: attempt.flightCardUrl
			}
		})
	}

	onUserSubmittedCertificationAttempt(event)
	{
		let attempt = new User.Certification.Attempt();
		attempt.id = event.attempt.id;
		attempt.type = event.type;
		attempt.submittedOn = event.occurredOn;
		attempt.status = 'pending';
		this.certification.attempts.push(attempt)
	}

	approveCertificationAttempt(issuingUserId, attemptId, reason)
	{
		let attempt = this.certification.attempts.find(a => a.id == attemptId);
		if(!attempt)
			throw new Error(`Invalid attempt id ${attemptId}`);

		if(attempt.status != 'pending')
			throw new Error(`Attempt ${attemptId} not pending, cannot approve`);
		
		let level = attempt.type == 'L3S1' ? 'level3' : attempt.type == 'L2S2' ? 'Level2' : attempt.type == 'L1S3' ? 'level1' : null;

		this.apply('userCertificationAttemptApproved', {
			issuingUserId,
			attemptId,
			type: attempt.type,
			reason,
			level,
			levelPassed: level != null
		})

		if(this.rso.mode == 'implicit' && level)
			this.grantRSO(issuingUserId, DateTime.now, 'implicit');
	}
	
	onUserCertificationAttemptApproved(event){
		let attempt = this.certification.attempts.find(a => a.id == event.attemptId);
		attempt.status = 'passed';

		if(event.levelPassed)
		{
			if(event.level == 'level1')
				this.certification.level1 = true;
			else if(event.level == 'level2')
				this.certification.level2 = true;
			else if(event.level == 'level3')
				this.certification.level3 = true;
		}
	}

	rejectCertificationAttempt(issuingUserId, attemptId, reason)
	{
		let attempt = this.certification.attempts.find(a => a.id == attemptId);
		if(!attempt)
			throw new Error(`Invalid attempt id ${attemptId}`);

		if(attempt.status != 'pending')
			throw new Error(`Attempt ${attemptId} not pending, cannot reject`);

		this.apply('userCertificationAttemptRejected', {
			issuingUserId,
			attemptId,
			type: attempt.type,
			reason
		})
	}

	onUserCertificationAttemptRejected(event){
		let attempt = this.certification.attempts.find(a => a.id == event.attemptId);
		attempt.status = 'failed';
		attempt.reason = event.reason;
	}

	grantCertification(issuingUserId, level, grantedOn)
	{
		switch(level)
		{
			case 'level1':
				if(this.certification.level1)
					throw new Error('User already has Level 1');
				break;
			case 'level2':
				if(this.certification.level2)
					throw new Error('User already has Level 2');
				break;
			case 'level3':
				if(this.certification.level3)
					throw new Error('User already has Level 3');
				break;
			default:
				throw new Error(`Invalid certification level ${level}`);
		}

		this.apply('userCertificationGranted', {
			issuingUserId,
			level,
			grantedOn
		})

		if(this.rso.mode == 'implicit')
			this.grantRSO(issuingUserId, grantedOn, 'implicit');
	}

	onUserCertificationGranted(event)
	{
		switch(event.level)
		{
			case 'level1':
				this.certification.level1 = true;
				break;
			case 'level2':
				this.certification.level1 = true;
				this.certification.level2 = true;
				break;
			case 'level3':
				this.certification.level1 = true;
				this.certification.level2 = true;
				this.certification.level3 = true;
				break;
		}
	}

	revokeCertification(issuingUserId, level)
	{
		switch(level)
		{
			case 'level1':
				if(!this.certification.level1)
					throw new Error('User does not have Level 1');
				break;
			case 'level2':
				if(!this.certification.level2)
					throw new Error('User does not have Level 2');
				break;
			case 'level3':
				if(!this.certification.level3)
					throw new Error('User does not have Level 3');
				break;
			default:
				throw new Error(`Invalid certification level ${level}`);
		}

		this.apply('userCertificationRevoked', {
			issuingUserId,
			level
		})

		if(this.rso.level == level)
			this.revokeRSOLevel(issuingUserId, level);
	}

	onUserCertificationRevoked(event)
	{
		switch(event.level)
		{
			case 'level1':
				this.certification.level1 = false;
				break;
			case 'level2':
				this.certification.level2 = false;
				break;
			case 'level3':
				this.certification.level3 = false;
				break;
		}
	}

	/// RSO

	requestRSOInterview(issuingUserId)
	{
		this.apply('userRSOInterviewRequested', {
			issuingUserId
		})
	}

	grantRSO(issuingUserId, grantedOn, mode, level)
	{
		if(level == 'level3' && !this.certification.level3)
			throw new Error('Cant grant L3 RSO to a non L3 cert');
		if(level == 'level2' && !this.certification.level2)
			throw new Error('Cant grant L2 RSO to a non L2 cert');
		if(level == 'level1' && !this.certification.level1)
				throw new Error('Cant grant L1 RSO to a non L1 cert');

		if(mode == 'implicit')
			level = this.certification.level3 ? 'level3' : this.certification.level2 ? 'level2' : this.certification.level1 ? 'level1' : 'model';

		this.apply('userRSOGranted', {
			issuingUserId,
			level,
			mode,
			grantedOn
		})
	}

	onUserRSOGranted(event)
	{
		this.rso.mode = event.mode;
		this.rso.level = event.level;
	}

	revokeRSOLevel(issuingUserId, level)
	{
		if(!this.rso.level)
			throw new Error('User does not have RSO');
		
		let newLevel = level == 'level3' ? 'level2' : level == 'level2' ? 'level1' : level == 'level1' ? 'model' : null

		this.apply('userRSOLevelRevoked', {
			issuingUserId,
			mode: this.rso.mode,
			level,
			newLevel
		})
	}

	onUserRSOLevelRevoked(event)
	{
		this.rso.level = event.newLevel;
	}

	revokeRSO(issuingUserId)
	{
		if(!this.rso.level)
			throw new Error('User does not have RSO');
		
		this.apply('userRSORevoked', {
			issuingUserId
		})
	}

	onUserRSORevoked(event)
	{
		this.rso.mode = null;
		this.rso.level = null;
	}

	/// MAP
	/////////////////////////////////////////////////////////

	
	submitMAP2Attempt(rocketId, flightId)
	{
		if(this.map2.attemptPending)
			throw new Error("Pending attempt already exists");

		this.apply('userSubmittedMAP2Attempt', {
			issuingUserId: this.id,
			userName: this.name,
			rocketId,
			flightId
		})
	}

	onUserSubmittedMAP2Attempt(event)
	{
		this.map2.attemptPending = true
	}

	approveMAP2Attempt(issuingUserId, attemptId, rocketId, flightId, result, reason)
	{
		let newLevel = getLevelForXP(this.map2.xp + result.xp);

		this.apply('userMAP2AttemptApproved', {
			issuingUserId,
			attemptId,
			rocketId,
			flightId,
			xp: this.map2.xp + result.xp,
			hydrazine: this.map2.hydrazine + result.hydrazine,
			level: newLevel,
			newLevel: this.map2.level != newLevel,
			tasks: result.tasks,
			badges: result.badges,
			reason,
		})
	}
	
	onUserMAP2AttemptApproved(event){
		this.map2.attemptPending = false;
		this.map2.xp = event.xp;
		this.map2.hydrazine = event.hydrazine;
		this.map2.level = event.level;
	}

	rejectMAPAttempt(issuingUserId, attemptId, reason)
	{
		let attempt = this.map.attempts.find(a => a.id == attemptId);
		if(!attempt)
			throw new Error(`Invalid attempt id ${attemptId}`);

		if(attempt.status != 'pending')
			throw new Error(`Attempt ${attemptId} not pending, cannot reject`);

		this.apply('userMAPAttemptRejected', {
			issuingUserId,
			attemptId,
			type: attempt.type,
			reason
		})
	}

	onUserMAPAttemptRejected(event){
		let attempt = this.map.attempts.find(a => a.id == event.attemptId);
		attempt.status = 'failed';
		attempt.reason = event.reason;
	}

	// Legacy MAPv1 event
	onUserGrantedMAP(event)
	{
		switch(event.level)
		{
			case 'level1':
				this.map.level1 = true;
				break;
			case 'level2':
				this.map.level2 = true;
				break;
			case 'level3':
				this.map.level3 = true;
				break;
		}
	}


	/// CERTIFICATES
	/////////////////////////////////////////////////////////

	requestCertificate(issuingUserId, stripeSessionId, type, shipping_details)
	{
		// dont check for valid type here as theyve already paid for it, let that cockup be worked out elsewhere (offline)

		this.apply('userCertificateRequested', {
			issuingUserId,
			stripeSessionId,
			type,
			shipping_details
		})
	}

	/// MEMBERSHIP
	/////////////////////////////////////////////////////////

	/**
	 * Actives the user's membership
	 * @param {The uid of the command-issuing user} issuingUserId 
	 * @param {The Stripe session id for debug} stripeSessionId 
	 * @param {DateTime of when the membership starts} startedOn 
	 * @param {DateTime of when the membership ends} expiresOn 
	 * @returns 
	 */
	activateMembership(issuingUserId, stripeSessionId, startedOn, expiresOn)
	{
		// idempotency check
		if(stripeSessionId != null && this.membership.stripeSessionId == stripeSessionId)
			return;
		
		this.apply('userMembershipActivated', {
			issuingUserId,
			stripeSessionId,
			startedOn,
			expiresOn
		})
	}

	onUserMembershipActivated(event) {
		this.membership.stripeSessionId = event.stripeSessionId;
		this.membership.startedOn = event.startedOn;
		this.membership.expiresOn = event.expiresOn;
	}

	expireMembership(issuingUserId)
	{
		if(this.membership.status != 'active')
			throw new Error('Membership not active');

		this.apply('userMembershipExpired', {
			issuingUserId,
			expiredOn: DateTime.now
		})
	}

	onUserMembershipExpired(event) {
		this.membership.expiresOn = event.expiredOn;
	}


	/**
	 * Requests delivery of a physical membership card
	 * @param {The uid of the command-issuing user} issuingUserId 
	 * @param {The Stripe session id for debug} stripeSessionId 
	 * @param {The user's shipping details} shipping_details 
	 */
	requestMembershipCard(issuingUserId, stripeSessionId, shipping_details)
	{
		// dont check for active membership here as theyve already paid for it, let that cockup be worked out elsewhere (offline)

		this.apply('userMembershipCardRequested', {
			issuingUserId,
			stripeSessionId,
			shipping_details
		})
	}

	recordGoogleWalletInUse(issuingUserId)
	{
		this.apply('userUsingGoogleWallet', {
			issuingUserId
		})
	}

	/// ACCOUNT
	/////////////////////////////////////////////////////////

	requestResetPassword(issuingUserId) {
		this.apply('userRequestedResetPassword', {
			issuingUserId,
			email: this.email
		});
	}

	onUserRequestedResetPassword(event) {
		// no op
	}

	
	requestEmailVerify(issuingUserId) {
		if(this.emailVerified)
			throw new Error(`User ${this.id} is already email verified`);
		
		this.apply('userRequestedEmailVerify', {
			issuingUserId,
			email: this.email
		});
	}

	onUserRequestedEmailVerify(event) {
		// no op
	}

	cycleApiKey(issuingUserId) {
		this.apply('userApiKeyCycled', {
			issuingUserId,
			apiKey: newId()
		})
	}


	delete(issuingUserId)
	{
		this.apply('userDeleted', { issuingUserId })
	}


	// numbers were imported as strings :facepalm:
	patchNumber()
	{
		if(Number.isInteger(this.number))
			return;

		this.apply('userNumberPatched', {
			issuingUserId: 'IMPORTER',
			number: parseInt(this.number, 10)
		})
	}

	patchCertGrantedOn(level, grantedOn)
	{
		this.apply('userCertGrantedOnPatched', {
			issuingUserId: 'IMPORTER',
			level, 
			grantedOn
		})
	}
}