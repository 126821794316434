import cardBg from '../../img/membership-card-bg.png';
import cardBackBg from '../../img/membership-card-back-bg.png';
import certBg from '../../img/certificate-bg.jpg';
import { certLevelToLevel, rsoLevelToLevel } from '../../../functions/domain/certification-utils.js';
import QRCode from 'qrcode'

export async function renderIdCard(user)
{
	let $canvas = document.createElement('canvas');
	$canvas.width = 1016;
	$canvas.height = 638;

	let ctx = $canvas.getContext("2d");
	
	await renderImage(ctx, cardBg, 0, 0, 1016, 638)

	await renderImage(ctx, user.avatar, 80, 220, 270, 290)

	renderText(ctx, user.name, 704, 282);
	
	let levelText = user.certification.level3.status == 'passed' ? 'Level 3' : user.certification.level2.status == 'passed' ? 'Level 2' : user.certification.level1.status == 'passed' ? 'Level 1' : '';
	if(user.rso.status == 'passed')
	{
		if(levelText)
			levelText += ' + '

		levelText += rsoLevelToLevel(user.rso.level) + ' RSO'
	}
	renderText(ctx, levelText, 704, 370, 'ds');

	renderText(ctx, "UKRA No: " + user.number, 704, 450, 'ds');
	renderText(ctx, "BMFA No: " + user.BMFANumber, 704, 500, 'ds');

	renderText(ctx, user.membership.expiresOn.year, 855, 600);

	const img = $canvas.toDataURL('image/png');

	return img;
}

export async function renderIdCardBack(user)
{
	let $canvas = document.createElement('canvas');
	$canvas.width = 1016;
	$canvas.height = 638;

	let ctx = $canvas.getContext("2d");
	
	await renderImage(ctx, cardBackBg, 0, 0, 1016, 638)
	let url = await QRCode.toDataURL(window.location.protocol + '//' + window.location.host + '/validate/' + user.id);
	await renderImage(ctx, url, 700, 130, 328, 328)


	let y = 260;
	const lineHeight = 38;
	renderText(ctx, 'Joined: ' + user.createdOn.format('yyyy/MM/dd'), 420, y, 'small');

	if(user.certification.level1.status == 'passed')
		renderText(ctx, 'Level 1: ' + user.certification.level1.passedOn.format('yyyy/MM/dd'), 420, y += lineHeight, 'small');
	
	if(user.certification.level2.status == 'passed')
		renderText(ctx, 'Level 2: ' + user.certification.level2.passedOn.format('yyyy/MM/dd'), 420, y += lineHeight, 'small');
	
	if(user.certification.level3.status == 'passed')
		renderText(ctx, 'Level 3: ' + user.certification.level3.passedOn.format('yyyy/MM/dd'), 420, y += lineHeight, 'small');
	
	if(user.rso.level)
		renderText(ctx, 'RSO: ' + user.rso.grantedOn.format('yyyy/MM/dd'), 420, y += lineHeight, 'small');

	const img = $canvas.toDataURL('image/png');

	return img;
}

export async function renderCert(user, level)
{
	if(user.certification[level].status != 'passed')
		return null;
	
	let $canvas = document.createElement('canvas');
	$canvas.width = 3508;
	$canvas.height = 2480;

	let ctx = $canvas.getContext("2d");
	
	await renderImage(ctx, certBg, 0, 0, 3508, 2480)

	ctx.textAlign = 'center'

	ctx.font = "100px Arial";
	ctx.fillText("This is to certify that",  $canvas.width / 2, 850);
	
	ctx.font = "bold 120px Arial";
	ctx.fillText(user.name,  $canvas.width / 2, 1025);

	ctx.font = "100px Arial";
	let levelText = `Attained ${certLevelToLevel(level)} Flight Certification`
	ctx.fillText(levelText, $canvas.width / 2, 1200);

	let date = user.certification[level].passedOn.format('do MMMM yyyy')
	ctx.fillText("On " + date, $canvas.width / 2, 1350);

	ctx.font = "80px Arial";
	let competencyText = 'Competent to fly rocket motors up to and including ' + (level == 'level1' ? 'I' : level == 'level2' ? 'L' : level == 'level3' ? 'O' : '???') + ' class'
	ctx.fillText(competencyText, $canvas.width / 2, 1550);

	let competencyText2 = '(Total impulse of ' + (level == 'level1' ? '640' : level == 'level2' ? '5,120' : level == 'level3' ? '40,960' : '???') + 'Ns)'
	ctx.fillText(competencyText2, $canvas.width / 2, 1700);

	const img = $canvas.toDataURL('image/png');

	return img;
}

export async function renderMAPCert(user, level)
{
	if(user.map[level].status != 'passed')
		return null;

	let $canvas = document.createElement('canvas');
	$canvas.width = 3508;
	$canvas.height = 2480;

	let ctx = $canvas.getContext("2d");
	
	await renderImage(ctx, certBg, 0, 0, 3508, 2480)

	ctx.textAlign = 'center'

	ctx.font = "100px Arial";
	ctx.fillText("This is to certify that",  $canvas.width / 2, 850);
	
	ctx.font = "bold 120px Arial";
	ctx.fillText(user.name,  $canvas.width / 2, 1025);

	ctx.font = "100px Arial";
	let levelText = `Attained MAP ${certLevelToLevel(level)}`
	ctx.fillText(levelText, $canvas.width / 2, 1300);

	ctx.fillText('UKRA Model Achievement Programme', $canvas.width / 2, 1450);

	let date = user.map[level].passedOn.format('do MMMM yyyy')
	ctx.fillText("On " + date, $canvas.width / 2, 1600);

	const img = $canvas.toDataURL('image/png');

	return img;
}

export async function renderRSOCert(user)
{
	if(user.rso.status != 'passed')
		return null;
	
	let $canvas = document.createElement('canvas');
	$canvas.width = 3508;
	$canvas.height = 2480;

	let ctx = $canvas.getContext("2d");
	
	await renderImage(ctx, certBg, 0, 0, 3508, 2480)

	ctx.textAlign = 'center'

	ctx.font = "100px Arial";
	ctx.fillText("This is to certify that",  $canvas.width / 2, 850);
	
	ctx.font = "bold 120px Arial";
	ctx.fillText(user.name,  $canvas.width / 2, 1025);

	ctx.font = "100px Arial";
	let levelText = `Attained Range Safety Officer (${rsoLevelToLevel(user.rso.level)})`
	ctx.fillText(levelText, $canvas.width / 2, 1200);

	let date = user.rso.grantedOn.format('do MMMM yyyy')
	ctx.fillText("On " + date, $canvas.width / 2, 1350);

	ctx.font = "80px Arial";

	let competencyText = 'Competent to authorise flights with motors up to'
	ctx.fillText(competencyText, $canvas.width / 2, 1550);
	
	competencyText = 'and including ' + (user.rso.level == 'level3' ? 'O' : user.rso.level == 'level2' ? 'L' : user.rso.level == 'level1' ? 'I' : 'G') + ' class'
	ctx.fillText(competencyText, $canvas.width / 2, 1650);

	let competencyText2 = '(Total impulse of ' + (user.rso.level == 'level3' ? '40,960' : user.rso.level == 'level2' ? '5,120' : user.rso.level == 'level1' ? '640' : '160') + 'Ns)'
	ctx.fillText(competencyText2, $canvas.width / 2, 1800);

	const img = $canvas.toDataURL('image/png');

	return img;
}

function renderImage(ctx, path, x, y, w, h)
{
	return new Promise((resolve, reject) => {
		const img = new Image();
		img.crossOrigin = 'anonymous'
		img.onload = () => {
			ctx.drawImage(img, x, y, w, h);
			resolve();
		};
		img.onerror = e => {
			console.error(e);
			reject(e);
		}
		img.src = path;
	})
}

function renderText(ctx, text, x, y, mode)
{
	ctx.textAlign = 'center'
	if(mode == 'ds')
	{
		ctx.font = "bold 40px Arial";
		ctx.shadowColor = "white";
		ctx.lineWidth = 6;
		ctx.strokeStyle = 'white'
		ctx.strokeText(text, x, y);

		ctx.fillStyle = "black";
		ctx.fillText(text, x, y);
	}
	else if(mode == 'small')
	{
		ctx.textAlign = 'left'
		ctx.font = "32px Arial";
  		ctx.fillText(text, x, y);
	}
	else
	{
		ctx.font = "bold 48px Arial";
  		ctx.fillText(text, x, y);
	}
}